.poditem{
  background-color: rgba(255, 255, 255, 0.25);
  padding: 10px ;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
}
.podImg_box > img{
  width: 100%;
  object-fit: cover;
  height: 250px;
  border-radius: 8px;

}
.podImg_box{
  position: relative;
}
.podImg_box .playbtn{
  top: 30%;
  position: absolute;
  left: 36%;
}
.podImg_box .playbtn img{
  max-width: 80px !important;
}
.custom_card {
  border-radius: 14px!important;
  box-shadow: 0 5px 15px 0 #00000026!important
}
