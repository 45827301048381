@media only screen and (max-width: 1500px) {
  .AdminDashboard .complaints_cardList > div,
  .UserDashboard .complaints_cardList > div {
    flex-basis: 33.3%;
    max-width: 33.3%;
  }
}
@media only screen and (min-width: 1370px) {
  .dashboard .cardgrid {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1200px) {
  .dashboard .cardGrid {
    flex: 0 0 50% !important;
    max-width: 100%;
  }
  .admindashboard_rows > div,
  .AdminDashboard .main_grid > div,
  .UserDashboard .main_grid > div,
  .Userdashboard_rows > div {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1100px) {
  .companyDetails .MuiGrid-item {
    max-width: 100%;
    flex-basis: 100%;
  }

  .companyDetails .leftcontent {
    text-align: left;
  }

  .browse {
    margin-right: 0;
  }

  .settings .settings-Datatable .toolbar {
    flex-direction: column;
    align-items: flex-start;
    margin-block: 10px;
    /* margin-inline: 10px; */
  }

  .settings .DTsearch {
    margin-left: 10px;
  }

  .settings .headingBox {
    flex-direction: column;
    align-items: flex-start;
  }

  .settings .headingBox .heading-btns {
    margin-block: 10px;
  }

  .logintype {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  .logintype .a-link {
    padding-top: 5px;
  }

  .dashboard_rows > div {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .AdminDashboard .complaints_cardList > div,
  .UserDashboard .complaints_cardList > div {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 900px) {
  .login_img {
    height: auto;
    min-height: 100px;
  }

  .logintext_box {
    position: relative;
    padding: 10vh 10vw;
    width: 76vw;
  }

  .settings .sidebar {
    display: none;
  }

  .settings .contentWraper {
    padding-left: 280px !important;
  }

  .sidebarclose .settings .contentWraper {
    padding-left: 80px !important;
  }

  .Res_settingsIcon {
    display: block !important;
  }

  .logintype {
    flex-direction: row;
    align-items: center;
    /* margin-top: 10px; */
  }

  .logintype .a-link {
    /* padding-top: 5px; */
  }
}

@media only screen and (max-width: 768px) {
  .Custom_drawer {
    display: none;
  }

  .res-menu-icon {
    display: block;
    color: #333333;
  }

  .contentWraper {
    padding-left: 20px !important;
    padding-top: 40px;
  }
  .withSubmenu .contentWraper {
    padding-left: 280px !important;
  }

  .settings .contentWraper {
    padding-left: 20px !important;
  }

  .showSettingMenu .Ressidebar {
    margin-left: 0 !important;
  }

  .appbar .account,
  .appbar .acct-menu {
    display: none;
  }

  .showAccountMenu .appbar .resAccountmenu .account {
    display: block;
  }

  .Res_AccountIcon {
    display: block !important;
    /* margin-left: auto !important; */
  }

  .sidebarclose .contentWraper {
    padding-left: 20px !important;
  }
  .change-password-tab {
    padding: 20px;
  }
  .sidebar {
    margin-left: 0;
  }
  /* .withSubmenu .contentWraper {
		padding-left: 280px !important;
	} */
}

@media only screen and (max-width: 600px) {
  .dashboard .cardGrid {
    flex: 0 0 100% !important;
    max-width: 100%;
  }

  .ComplainModal .MuiPaper-root {
    width: auto;
  }

  .companies .Datatable .companiesToolbar {
    flex-direction: column;
    align-items: flex-start;
    padding-block: 10px;
  }

  .companies .Datatable .DTsearch {
    margin-left: 0px;
    margin-top: 10px;
  }

  .comptoolbar {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start !important;
    margin-block: 10px;
  }

  .addRoleForm_modal .flex-col-rev {
    min-width: 45%;
  }

  .echarts {
    max-height: 300px;
  }

  .chart600 {
    max-height: 600px;
  }

  .companies .headingBox,
  .settings .headingBox,
  .Addcompany .headingBox,
  .Complains .headingBox,
  .companyDetails .headingBox,
  .notification .headingBox {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
    margin-bottom: 20px;
  }
  .DTsearch {
    margin-left: 0;
  }
  .settingUsers_heading {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
    margin: 10px 0;
  }
  .swiper {
    max-width: 100%;
  }
  .comment_each {
    flex-wrap: wrap;
  }
  .comment_box {
    flex-wrap: wrap;
  }
  .comment_box .textfield {
    flex: 1;
  }
  .comment_box button {
    width: 100%;
    margin-top: 20px;
  }
  .comments_section .sender {
    padding: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .diffFile_type {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
  }

  .companyDetails .whiteContainer .toolbar {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 10px;
  }
  .diffFile_type h6 {
    margin-bottom: 20px;
  }

  .adduserAlert_Modal .text {
    padding-block: 0;
  }

  .adduserAlert_Modal .MuiPaper-root {
    padding-inline: 0px;
  }

  .DT-pagination .MuiToolbar-root {
    padding-inline: 9px;
  }

  .DT-pagination .MuiInputBase-root {
    margin-right: 10px;
  }

  .DT-pagination .MuiTablePagination-actions {
    margin-left: 0px !important;
  }
  .AdminDashboard .complaints_cardList > div,
  .UserDashboard .complaints_cardList > div {
    flex-basis: 100%;
    max-width: 100%;
  }
  #compAddUser .MuiDialogActions-root {
    padding-inline: 7px;
  }
}

@media only screen and (max-width: 400px) {
  .logintext_box {
    width: 70vw;
  }

  .all-details > .MuiBox-root {
    justify-content: space-between;
  }

  .all-details h6 {
    min-width: auto;
  }

  .settings .headingBox .heading-btns button {
    margin: 5px 0;
  }

  .settings .settings-Datatable .toolbar {
    margin-inline: 0;
  }

  .logintype {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  .logintype .a-link {
    padding-top: 5px;
  }

  .addRoleForm_modal .flex-col-rev {
    min-width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  .resAccountmenu {
    display: none;
  }
}

@media (max-width: 600px) {
  .header_main_left img {
    height: 60px !important;
  }
  .auth_page .theme_btn {
    padding: 8px !important;
  }

  .card-lg {
    padding: 0px 30px 0px 20px;
  }
  .auth_footer .social_media_icons > span {
    width: 100%;
    margin-bottom: 10px;
  }
  .auth_page form .theme_btn {
    max-width: 100%;
  }
  .hero_banner_in .description {
    max-width: 100%;
  }
  .newsletter .textfield {
    width: 100%;
  }
  .newsletter button {
    margin-top: 20px !important;
    margin: auto;
  }
  .courses .card.bg-shade4 {
    min-height: 240px;
  }
  .choosegrid .MuiGrid-item .flex-center > div {
    max-width: 100%;
    padding: 20px 20px 0;
    text-align: center;
  }
  .choosegrid .MuiGrid-item img {
    margin-right: 0;
  }
  .whypeople_container .left_content {
    flex-wrap: wrap;
  }
  .whypeople_container .MuiGrid-container {
    margin: 0;
  }
  .whypeople_container .MuiGrid-item {
    padding-left: 0 !important;
  }
  .whypeople_container .contentbox > div:first-child {
    padding: 80px 20px 60px;
  }
  .whypeople_container .MuiGrid-item:first-child {
    padding: 0 40px;
    padding-left: 40px !important;
  }
  .whypeople_container .ryt_content > div {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
  .growing_container span {
    padding: 0;
    width: 100%;
    display: block;
  }
  .growing_container span {
    font-size: 60px !important;
  }
  .growing_container {
    padding: 12px;
  }
}

@media (min-width: 600px) {
  .flex-sm-nowrap {
	flex-wrap: nowrap !important;
  }
}
